div.hobby-img {
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: none;
}

img.hobby-img {
    width: 100%;
    display: none;
}

div.hobby-img:first-child {
    display: block;
}

img.hobby-img:first-child {
    display: none;
}

.card-img-top.hobby-img.expanded {
    background-size: contain;
}

.hobby-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    transition: max-height 3s;
}

.hobby-cards .card {
    max-width: 49%;
    width: 300px;
    position: relative;
    margin-bottom: 5px;
}

.hobby-cards .next,
.hobby-cards .last,
.hobby-cards .expand,
.hobby-cards .shrink {
    position: absolute;
    top: 170px;
    background: #00000070;
    color: white;
    border-radius: 5px;
}

.hobby-cards .next {
    right: 5px;
}

.hobby-cards .expand,
.hobby-cards .shrink {
    top: 5px;
    right: 5px;
}

.hobby-cards .shrink {
    display: none;
}

.hobby-cards .last {
    left: 5px;
}

.hobby-cards .hobby-load-more {
    max-width: 100%;
    width: 100%;
    height: 25px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    display: none;
    background-color: transparent;
    position: relative;
    margin-bottom: 5px;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .hobby-cards .card {
        display: none;
    }

    .hobby-cards .card.show-card {
        display: flex;
    }

    .hobby-cards {
        max-height: 600px;
    }

    .hobby-cards .hobby-load-more {
        display: flex;
    }

}