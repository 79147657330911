body,
html {
    height: 100%;
    margin: 0;
    justify-content: center;
    align-items: center;
}

html {
    overflow: hidden;
}

body {
    background-color: #f8f9fa;
    font-family: Helvetica, Arial, sans-serif;
}

.container {
    background-color: #f8f9fa;
}

header {
    background: linear-gradient(45deg, rgb(111 164 203) 15%, rgb(16 108 175) 75%)
}

.iframe-col-12 {
    display: flex;
    justify-content: center;
    align-items: center;
}

#oopsField {
    display: none;
    color: red;
    font-size: small;
}


.tab input[type=range] {
    width: 100%;
}

.qualifications>div {
    margin-bottom: 20px;
}

/* Footer styling */
#footer-row {
    background-color: #333;
    color: white;
    text-align: center;
    font-size: 14px;
    position: absolute;
    width: 100%;
    z-index: 1500;
    padding: 5px 0;
    margin-top: 50px;
    margin-left: 0px;
}

.footer-row .social a {
    margin: 0 10px;
    color: #fff;
    font-size: 18px;
}

.footer-row .social a:hover {
    color: #FFB800;
}

#nameInput {
    background-color: aliceblue;
}


.profile-photo {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.highlight-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.blockquote-footer {
    padding: 20px 0;
}

.social-icons a {
    margin: 0 10px;
    color: white;
}

.col-md-6 {
    margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
    .col-12 {
        margin-bottom: .5rem !important;
    }

    .highlight-card p {
        font-size: small;
    }

    #duo-link img {
        height: 15px;
    }

    .blockquote-footer {
        padding: 20px 0;
        font-size: medium;
    }
}

/* Quiz */
#quiz {
    text-align: center;
    padding: 20px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background-color: aquamarine;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;
    opacity: 100%;
    transition: ease-in-out 2s;
}

.petQ2 {
    height: auto;
    max-height: 0px;
    overflow: hidden;
    margin-top: -20px;
    transition: max-height linear 1s;
}

#rejection {
    color: white;
    font-size: xxx-large;
    position: absolute;
    text-align: center;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    top: 35%;
    transition: ease-in-out 1s;
    width: 100%;
    z-index: 900;
    padding: 25px;
    display: none;
}

img.inline-img {
    overflow: hidden;
    height: var(--bs-body-font-size)
}

#rejection h1 {
    opacity: 0%;
    transition: ease-in-out 1s;
}

#rejection h2 {
    padding-left: 40px;
    padding-right: 40px;
    opacity: 0%;
    transition: ease-in-out 1s;
}

.rainbow {
    animation: colorRotate 6s linear 0s infinite;
}

@keyframes colorRotate {
    from {
        color: #6666ff;
    }

    10% {
        color: #0099ff;
    }

    50% {
        color: #00ff00;
    }

    75% {
        color: #ff3399;
    }

    100% {
        color: #6666ff;
    }
}

#tab1 h2 {
    color: #000;
    transition: ease-in 1s;
}

#rickRoll {
    align-items: end;
    background-color: aquamarine;
    background-image: image-set(url("https://i.giphy.com/Ju7l5y9osyymQ.webp") type("image/webp"), url("https://i.giphy.com/Ju7l5y9osyymQ.webp") type("image/webp"));
    background-image: url("https://i.giphy.com/Ju7l5y9osyymQ.webp");
    background-position-x: center;
    background-size: auto 110%;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    padding-bottom: 20%;
    position: fixed;
    text-align: center;
    top: 0;
    transition: 3s;
    width: 100%;
    z-index: 800;
    display: none;
}

@media screen and (max-width: 768px) {
    #rickRoll.slide {
        animation: rickRollAnimation2 15s infinite;
        animation-direction: normal;
        background-size: auto 15%;
    }

    #tab1 {
        width: 100%;
    }
}

@media screen and (min-width: 769px) {
    #rejection {
        top: 34%;
    }

    #rickRoll.slide {
        animation: rickRollAnimation2 90s infinite;
        animation-direction: normal;
        background-size: auto 15%;
    }
}

#rickRoll.move {
    animation: rickRollAnimation1 15s infinite;
    background-position-x: center;
    animation-direction: alternate;
}



@keyframes rickRollAnimation1 {
    from {
        background-size: auto 110%;
    }

    20% {
        background-size: auto 100%;
    }

    80% {
        background-size: auto 15%;
    }

    to {
        background-size: auto 10%;
    }
}

@keyframes rickRollAnimation2 {
    from {
        background-position-x: 50%;
    }

    to {
        background-position-x: 450%;
    }
}

.tab {
    display: none;
}

.question {
    margin-bottom: 20px;
    position: relative;
}

.question i,
.question svg,
.question img {
    position: absolute;
    padding-bottom: 2px;
    stroke-width: 1;
    height: 24px;
    width: 24px;
}

.question i:first-of-type,
.question svg:first-of-type,
.question img:first-of-type {
    left: 0;
}

.question i:last-of-type,
.question svg:last-of-type,
.question img:last-of-type {
    right: 0;
}

#whyCollapse {
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

#whyCollapse.collapsed {
    max-height: 0px;
}

.fold-me.why {
    top: 60px;
}

.fold-me i,
.fold-me svg,
.fold-me svg polyline {
    pointer-events: none;
}

@media screen and (min-width: 768px) {
    #whyCollapse.collapsed {
        max-height: 500px;
    }

}

.carousel-inner div {
    height: 500px;
    background-size: cover;
    background-position: 50%;
}

#tab1 {
    min-width: 50%;
}

/* Input Sliders */
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        width: 100px;
        -webkit-appearance: none;
        background-color: #bb51c5;
        background: linear-gradient(to right, #9c0000 0%, #4b78ca 50%, #00ac09 100%);
    }

    input[type='range'].politics {
        background: linear-gradient(to right, #006eff 10%, #ff0000 90%);
    }

    input[type='range'].spiritual {
        background: linear-gradient(to right, #000 10%, #fff 90%);
    }

    input[type='range'].petsSlider {
        background: linear-gradient(to right, #0084ff 20%, #ff9c08 80%);
    }

    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        cursor: ew-resize;
        background: whitesmoke;
        border: #000 2px solid;
        opacity: 100%;
        /* border-radius: 10px; */
    }

    input[type='range'].hideThumb::-webkit-slider-thumb {
        opacity: 2%;
        cursor: default;
    }

    input[type='range'].hideThumb:hover {
        cursor: default;
    }

}

/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: #43e5f7;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7;
}

#contactMe {
    max-width: 600px;
    margin: auto;
    background-color: #dceafd;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 5px 1px 10px black;
}

.input-group-prepend .input-group-text {
    border-end-end-radius: 0px !important;
    border-start-end-radius: 0px !important;
    ;
}

#contactFormThanks {
    color: green;
}