.chat-window {
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    padding: 15px;
    align-items: flex-end;
    justify-content: flex-end;
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
}

@media (min-height: 600px) {
    .chat-window {
        position: fixed;
        bottom: 10%;
        max-width: 100%;
        max-height: 80%;
    }
}

.chat-avatar img.close {
    position: absolute;
    bottom: 55px;
    right: 8px;
    height: 20px;
    width: 20px;
    z-index: 1000;

}

.chat-window.open .chats {
    display: flex;
}

.chat-window.open .chat-avatar img {
    opacity: 1;
}

.chat-window .chat-avatar img.close {
    opacity: 0;
}

.chat-window.open .chat-avatar img.close {
    opacity: 0.8;
}

.chats {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chats::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chats {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.chat-entry {
    display: flex;
    margin-bottom: 10px;
    pointer-events: auto;
}

.chat-entry.pic {
    justify-content: flex-end;
}

.chat-bubble {
    position: relative;
    background: #218aff;
    text-align: right;
    min-width: 45%;
    padding: 10px 15px;
    border-radius: 6px;
    border: 1px solid #218aff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: 10px;
    color: white;
}

.chat-bubble.pic {
    padding: 2px;
}

.chat-bubble img {
    width: 100%;
    border-radius: 6px;
    max-height: 300px;
}

.prompt .chat-bubble::before {
    content: '';
    position: absolute;
    visibility: visible;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    border: 10px solid transparent;
    border-left: 10px solid #218aff;
    margin-left: 10px;
}

.prompt .chat-bubble::after {
    content: '';
    position: absolute;
    visibility: visible;
    top: 50%;
    right: -18px;
    transform: translateY(-50%);
    border: 10px solid transparent;
    border-left: 10px solid #218aff;
    clear: both;
}

.prompt {
    align-self: flex-end;
}

.chat-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
}

.chat-avatar img {
    opacity: 0.5;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: 1s ease;
    margin: 0px;

}

@keyframes glow {
    0% {
        box-shadow: 0 0 5px rgba(255, 0, 0, 0.0), 0 0 10px rgba(255, 0, 0, 0.0), 0 0 15px rgba(255, 0, 0, 0.0), 0 0 20px rgba(255, 0, 0, 0.0);
    }

    50% {
        box-shadow: 0 0 5px rgba(0, 0, 255, 0.5), 0 0 10px rgba(0, 0, 255, 0.5), 0 0 15px rgba(0, 0, 255, 0.5), 0 0 20px rgba(0, 0, 255, 0.5);
    }

    100% {
        box-shadow: 0 0 5px rgba(255, 0, 0, 0.0), 0 0 10px rgba(255, 0, 0, 0.0), 0 0 15px rgba(255, 0, 0, 0.0), 0 0 20px rgba(255, 0, 0, 0.0);
    }
}

.chat-avatar img.hidden {
    height: 0px !important;
    width: 0px !important;
    margin: 25px;
}

.chat-avatar img:hover {
    cursor: pointer;
    opacity: 0.5;
}

.response {
    align-self: flex-start;
}

.response .chat-bubble {
    color: black;
    position: relative;
    background: #fdfdfd;
    text-align: left;
    min-width: 45%;
    padding: 10px 15px;
    border-radius: 6px;
    border: 1px solid #ccc;
    /* Add border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Add shadow */
    margin-left: 10px;
    margin-right: 0;
    align-self: flex-start;
    /* Align response bubbles to the left */
}

.response .chat-bubble::before {
    content: '';
    position: absolute;
    visibility: visible;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    border: 10px solid transparent;
    border-right: 10px solid #ccc;
}

.response .chat-bubble::after {
    content: '';
    position: absolute;
    visibility: visible;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
    border: 10px solid transparent;
    border-right: 10px solid #fdfdfd;
    clear: both;
}